var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-container lists-container" },
    [
      _c("CategoryTitle", {
        staticClass: "hidden-xs-only",
        attrs: { category: _vm.category }
      }),
      _c("ListsMenu", {
        attrs: { listsData: _vm.lists },
        on: {
          setdefault: _vm.setDefaultList,
          remove: _vm.removeList,
          createlist: _vm.createListt,
          rename: _vm.renameList
        }
      }),
      _vm.lists && _vm.lists.length == 0
        ? _c(
            "v-card",
            {
              staticClass: "pa-2 text-center",
              staticStyle: { width: "100%" },
              attrs: { light: "", outlined: "" }
            },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Nessuna lista trovata")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }